.root {
	position: relative;
	padding: .5em 0;
	display: grid;
	max-width: 35rem;
	gap: 1em;
	grid-template-columns: max-content auto;
	align-items: start;
	background-color: var(--b);
	grid-template-areas:
		"simpleTab simpleInfo"
		"advancedTab advancedInfo";
		/* "customTab customInfo"; */
}

.tab:nth-child(1) { grid-area: simpleTab; }
.tab:nth-child(2) { grid-area: advancedTab; }
/* .tab:nth-child(3) { grid-area: customTab; } */

.infoCommons {
	font-size: small;
	padding: .5em 1em;

	display: grid;
	width: 100%;
	height: 100%;
	gap: 1em;
	grid-template-columns: repeat(auto-fill, minmax(20px, auto));
	align-items: center;
	justify-content: start;
}

.simpleModeInfo {
	composes: infoCommons;
	grid-area: simpleInfo;
}

.advancedModeInfo {
	composes: infoCommons;
	grid-area: advancedInfo;
}

.customModeInfo {
	composes: infoCommons;
	grid-area: customInfo;
	grid-template-columns: auto;
}

.customModeInfo svg {
	width: 20px;
	height: 20px;
}

.infoLogo {
	display: inline-block;
	filter: grayscale() brightness(0);
}

.tab {
	position: relative;
	background: none;
	border: none;
	color: var(--t);
	font-weight: 500;
	width: max-content;
	transition:
		color .3s,
		box-shadow .3s;
	transition-timing-function: var(--elevate-bezier);

	padding: .5em 1em;
	border-radius: 9999px;
	z-index: 2;
}

.tab::before {
	display: block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: inherit;
	transition: box-shadow .3s;
	transition-timing-function: var(--elevate-bezier);
	z-index: -2;
}

.tab:not([data-active="true"]):hover::before {
	box-shadow: var(--neumorphic);
}

.tab:not([data-active="true"]):focus {
	box-shadow: inset 0 0 0 3px var(--a1-translucent-weaker);
}

.tab:focus,
.tab:active {
	outline: none;
}

.tab[data-active="true"] {
	color: var(--a1-c);
}

.tab[data-active="true"]:hover ~ .pill {
	filter: brightness(90%);
}

.pill {
	composes: accent from '../button/button.module.css';
	position: absolute;
	top: 0;
	left: 0;
	will-change: transform, width, height;
	transition:
		transform .5s,
		width .5s,
		height .5s,
		filter .5s;

	transition-timing-function: var(--elevate-bezier);
	border-radius: 9999px;
	pointer-events: none;
	box-shadow: var(--elevate-before);
	background-image: var(--a1-gradient);
	border: none;
}
