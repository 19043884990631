.hero {
	position: relative;
}

.content {
	max-width: 35rem;
	background-color: var(--b);
	margin: 1rem 0 4rem -1em;
	padding-left: 1em;
	box-shadow: var(--separate);
}

.h1 {
	font-size: 4rem;
	text-shadow: var(--elevate-before);
}

.rose {
	background-image: radial-gradient(
			circle farthest-corner at 20% 200%,
			rgb(255, 225, 125) 0%,
			rgb(255, 205, 105) 10%,
			rgb(250, 145, 55) 28%,
			rgb(235, 65, 65) 42%,
			transparent 82%
		),
		linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);

	border-radius: 1rem;
	background-clip: text;
	text-shadow: none;
	color: transparent;
	filter: var(--elevate-before-filter);
}

.p {
	font-size: large;
	text-shadow: var(--elevate-weak);
}

.upload {
	margin-top: 3rem;
	margin-bottom: 3rem;
	padding: 2rem;
	z-index: 2;

	border-radius: 1rem;

	box-shadow: var(--neumorphic);
}

.uploaderWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4rem 0;
}

.h2 {
	margin-bottom: 2rem;
}

.grid {
	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(40px, auto));
	align-items: start;
	justify-content: start;
}

.infoLogo {
	border-radius: 0.5em;
}

.background {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	max-width: 35rem;
	width: 100%;

	display: flex;
	flex-direction: column;
}

.wrapperCommons {
	max-width: 20rem;
}

.likeImageWrapper {
	composes: wrapperCommons;
	align-self: flex-end;
}

.instagramImageWrapper {
	composes: wrapperCommons;
	margin-top: 4rem;
	display: inline-block;
}

.heartImageWrapper {
	composes: wrapperCommons;
	align-self: flex-end;
}

@media not prefers-reduced-motion {
	.wrapperCommons {
		animation: levitate 15s infinite;
	}

	.likeImageWrapper {
		animation-delay: 2s;
		animation-timing-function: var(--elevate-bezier);
	}

	.instagramImageWrapper {
		animation-duration: 15s, 180s;
		animation-name: levitate, rotate;
		animation-timing-function: var(--elevate-bezier), linear;
	}

	.heartImageWrapper {
		animation-delay: 4s;
	}
}

@keyframes levitate {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.tabsWrapper {
	position: relative;
	overflow: hidden;
}

.download {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 10;

	display: inline-flex;
	align-items: center;
	box-shadow: var(--elevate-before) !important;
}

.download[aria-busy='true'] {
	pointer-events: none;
}

.iconCommons {
	width: 1rem;
	height: 1rem;
	margin-right: 0.5em;
}

.loading {
	composes: iconCommons;
	animation: rotate 1000ms infinite linear;
}

.arrowDown {
	composes: iconCommons;
	transition: transform 0.5s;
	transition-timing-function: var(--elevate-bezier);
}

.download:hover .arrowDown {
	transform: translateY(2px);
	transition: transform 0.1s;
}

.tab:not(:global [class*='tab-']) {
	will-change: opacity;
	display: none;
}

/* :global .tab-appear-active {
	transform: translateY(20px) scale(0.97);
	opacity: 0;
	pointer-events: none;
	animation:
		appear-position 1s,
		appear-opacity 2s;

	animation-fill-mode: forwards;
	animation-timing-function: var(--elevate-bezier);
	animation-delay: .2s;
}

:global .tab-appear-done {
	transform: none;
	opacity: 1;
} */

:global .tab-enter {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
}

:global .tab-enter-active {
	opacity: 1;
	transition: opacity 0.5s;
	transition-timing-function: var(--elevate-bezier);
}

:global .tab-exit {
	opacity: 1;
}

:global .tab-exit-active {
	opacity: 0;
	transition: opacity 0.5s;
	transition-timing-function: var(--elevate-bezier);
}

:global .tab-exit-done {
	display: none;
}

@keyframes appear-position {
	from {
		transform: translateY(20px) scale(0.97);
	}
	to {
		transform: translateY(0) scale(1);
	}
}

@keyframes appear-opacity {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.a {
	color: var(--t);

	text-decoration: none;
	background-image: var(--link-bg);
	background-repeat: repeat-x;
	background-position: left bottom;

	transition: color 0.3s;
}

.a[href^='http']::after {
	content: ' ↗';
	user-select: none;
	pointer-events: none;
}

.a:hover {
	outline: none;
	color: var(--a1);
	background-image: var(--link-bg-focus);
	transition-duration: 0s;
}

.a:focus {
	outline: 2px solid var(--a1);
	outline-offset: 2px;
	color: var(--a1);
	background-image: none;
	transition-duration: 0s;
}
