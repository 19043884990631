.root + .root {
	margin-top: 4rem;
}

.heading {
	margin-top: 0;
	margin-bottom: 0;
	display: inline;
	vertical-align: top;
	line-height: 40px;
}

.image {
	display: inline;
	margin-right: 1em;
	line-height: 1;
	filter: var(--elevate-before-filter);
}

.grid {
	display: flex;
	flex-wrap: wrap;
	margin: -.5rem;
}

.grid > * {
	flex-shrink: 2;
	margin: .5rem;
}

.description {
	max-width: 30rem;
}
