.root {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: .5rem;
}

.heading {
	display: inline-block;
	margin-top: 0;
	margin-bottom: .5em;
	font-size: medium;
}

.description {
	display: inline-block;
	flex-grow: 0;
	max-width: 20rem;
	font-size: small;
}

.descriptionImage {
	float: left;
	margin-right: .5em;
}

.p {
	display: inline;
	margin: 0;
	position: relative;
	top: -.2em;
}

.imageWrapper {
	position: relative;
	display: inline-block;

	max-width: 20rem;
	border-radius: .5em;
	margin-top: .5em;
}

.imageWrapperGhost {
	composes: imageWrapper;
	pointer-events: none;
}

.imgTiltWrapper,
.imgTiltWrapperError {
	position: relative;
	width: 100%;
}

.imgTiltWrapper [data-rmiz-trigger] {
	margin: 0 !important;
	z-index: 3;
	outline: none !important;
}

.imgTiltWrapperError [data-rmiz-trigger],
.imgTiltWrapperError .infoOverlay {
	display: none;
}

.elevateBefore {
	box-shadow: var(--elevate-before);
	transition-property: box-shadow;
	transition-duration: .3s;
	transition-timing-function: var(--elevate-bezier);
}

.errorContainer {
	composes: elevateBefore;
	position: relative;
	border-radius: .5em;
	overflow: hidden;
	max-width: 100%;
}

.errorContainerContent {
	composes: elevateBefore;
	box-shadow: var(--elevate-weak);
	border-radius: .5em;
	background-color: var(--b);
	color: var(--t);
	padding: .5em .5em 1em;
	font-size: small;
	margin: .5em;
}

.errorContainerContentIcon {
	margin-right: .5em;
	vertical-align: middle;
}

.errorContainerBg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	filter: blur(20px);
	z-index: -1;
	opacity: .85;
}

.errorContainerBgFallback {
	composes: errorContainerBg;
	z-index: -2;
	background-image: url('./static.gif');
	background-size: cover;
	background-position: center;
	background-repeat: repeat;
}

.errorContainerButtonWrapper {
	display: flex;
	justify-content: center;
}

.errorContainerButton {
	composes: accent from '../button/button.module.css';
	font-size: medium;
	padding: .3em;
	line-height: 0;
	display: block;
	width: 100%;
	max-width: 4rem;
	margin-top: .5em;

	display: flex;
	justify-content: center;
}

.image {
	composes: elevateBefore;
	min-width: 10rem;
	min-height: calc(1.73em + .5em * 2);
	width: 100%;
	border-radius: .5em;

	background-image: url("data:image/svg+xml,%3Csvg height='20px' width='20px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Crect x='0' y='0' width='10' height='10' fill-opacity='0.5'%3E%3C/rect%3E%3Crect x='10' y='0' width='10' height='10' fill-opacity='0.1'%3E%3C/rect%3E%3Crect x='0' y='10' width='10' height='10' fill-opacity='0.1'%3E%3C/rect%3E%3Crect x='10' y='10' width='10' height='10' fill-opacity='0.5'%3E%3C/rect%3E%3C/svg%3E");
	background-repeat: repeat;
}

.imageWrapper:focus-within .errorContainer,
.imageWrapper:hover .errorContainer,
.imageWrapper:focus-within .image,
.imageWrapper:hover .image {
	box-shadow: var(--elevate-after);
}

.fakeImageWrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
}

.fakeImageWrapperZoomed {
	composes: fakeImageWrapper;
	opacity: 1;
	transform: translateZ(1100px);
}

.fakeImage {
	border-radius: .5em;
	width: 100%;
	box-shadow: var(--elevate-after);
}

:global [data-rmiz-overlay],
:global [data-rmiz-overlay] img {
	transform: translateZ(1000px);
}

:global [data-rmiz-overlay] > div:first-child {
	opacity: 0;
	transition-property: opacity !important;
	transition-duration: .3s !important;
	transition-timing-function: var(--elevate-bezier) !important;
}

.checkboxWrapper {
	position: absolute;
	top: .5rem;
	left: .5rem;
	z-index: 4;
	line-height: 1;
	padding: .3em;
	cursor: pointer;
	color: var(--b-weaker);
	user-select: none;
}

.checkbox {
	opacity: 0;
}

.checkboxBack,
.checkboxFront {
	position: absolute;
	top: 2px;
	left: 2px;
	line-height: 1;
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
	transition: opacity .3s, color .1s;
	transition-timing-function: var(--elevate-bezier);
}

.checkboxFront {
	opacity: 0;
}

.checkboxBack {
	filter: drop-shadow(0 0 4px var(--t));
}

.checkbox:focus ~ .checkboxFront,
.checkbox:focus ~ .checkboxBack {
	color: var(--a1);
}

.checkbox:checked ~ .checkboxFront {
	opacity: 1;
	transition-duration: .1s;
}

.infoOverlay {
	position: absolute;
	top: 0;
	right: 0;
	padding: .5rem;
	z-index: 4;
	transform: translateZ(100px);
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}

.gradient {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
	transition: opacity .5s var(--elevate-bezier);
}

.infoOverlayContent {
	position: relative;
	display: flex;
	align-items: center;
	height: 1.73em;
	width: max-content;
}

.infoOverlayText {
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 2em;
	color: var(--t);
	font-size: small;
	opacity: 0;
	transition: opacity .1s var(--elevate-bezier);
	pointer-events: none;
	z-index: 2;
}

.download {
	composes: root from '../button/button.module.css';
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	padding: .3em;
	line-height: 0;

	opacity: .3;
	transition-property: color, border-color, background-color, opacity, width;
	transition-duration: .1s;
	transition-timing-function: var(--elevate-bezier);

	position: absolute;
	top: 0;
	right: 0;
	width: 1.73em;
}

.imageWrapper:focus-within .infoOverlay,
.imageWrapper:hover .infoOverlay,
.download:focus,
.imageWrapper:hover .download {
	opacity: 1;
}

.infoOverlay:focus-within .infoOverlayText,
.imageWrapper:hover .infoOverlayText {
	opacity: 1;
}

.imageWrapper:focus-within .gradient,
.imageWrapper:hover .gradient {
	opacity: .8;
}

.download:focus,
.imageWrapper:hover .download {
	color: var(--t);
	width: calc(100% + .4em);
}
